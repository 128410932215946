import React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../components/Layout';
import BlockContent from '@sanity/block-content-to-react';

export const query = graphql`
  query AboutMe {
    sanityAuthor {
      name
      image {
        asset {
          fluid {
            src
          }
        }
      }
      _rawBio
    }
  }
`;

const AboutMe = props => {
  const { data, errors } = props;

  const content = data.sanityAuthor;
  return (
    <Layout fullMenu>
      <section id="wrapper">
        <header>
          <div className="inner">
            <h2>{content.name}</h2>
          </div>
        </header>

        <div className="wrapper">
          <div className="inner">
            <section className="features">
              <BlockContent
                blocks={content._rawBio}
                imageOptions={{ w: 320, h: 320, fit: 'min' }}
                projectId="exs5mr40"
                dataset="production"
              />
            </section>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default AboutMe;
